<template>
  <div class="mt-3" v-if="!isLoggedIn">
    <h1 class="mb-3">
      {{ $t('pwrm.t') }}
    </h1>
    <b-card class="mt-3" header-tag="nav">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item to="/" exact exact-active-class="active"><b-icon-house v-if="!isXs" v-bind="iH" /> {{ $t('nav.home') }}</b-nav-item>
          <b-nav-item active exact exact-active-class="active"><Icon type="e" isNav :isButton="false" /> {{ $t('btn.r') }}</b-nav-item>
        </b-nav>
      </template>
      <b-form class="text-center mt-3" id="pwrForm" @submit.stop.prevent>
        <b-form-group :label="$t('lgin.f.emaill')" label-for="pwrEmail" class="text-left">
          <b-form-input type="email" id="pwrEmail" v-model="f.emailAddress" :state="valEmail" required />
          <b-form-invalid-feedback :state="valEmail">
            {{ $t('lgin.f.emailf') }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('lgin.f.pwl')" label-for="pwrPw" class="text-left">
          <b-form-input type="password" @keydown.enter.native="onSubmit" id="pwrPw" v-model="f.password" :state="valPw" class="mt-2" pattern=".{8,}" required />
          <b-form-invalid-feedback :state="valPw">
            {{ $t('lgin.f.pwf') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="text-right mt-3 mb-0">
          <b-button type="submit" variant="primary" class="ml-2" id="pwrSubmit" :disabled="!val" @click="sendData()">
            <Icon type="e" /> {{ $t('btn.r') }}
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { BIconHouse } from 'bootstrap-vue';
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      f: {
        uuid4: null,
        emailAddress: '',
        password: '',
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    valEmail() {
      if (this.f.emailAddress === '') return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.emailAddress.toLowerCase());
    },
    valPw() {
      if (this.f.password === '') return null;
      return this.f.password.length >= 8 ? true : false;
    },
    val() {
      return (this.valEmail === true && this.valPw === true);
    },
    iH() {
      return this.$store.getters.iH;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  created() {
    this.prepare(this.$route.params.uuid);
  },
  beforeRouteUpdate(to, _, next) {
    this.prepare(to.params.uuid);
    next();
  },
  methods: {
    prepare(uuid) {
      if (this.isLoggedIn) {
        this.$store.commit('showWarn', this.$t('pwrm.w'));
        this.$router.replace('/').catch(() => {});
      } else {
        this.f.uuid4 = uuid;
      }
    },
    sendData() {
      if (this.val) {
        REST.patch('/tasks/resetpw/' + this.f.uuid4, this.f
          ).then(resp => {
            this.$router.push('/');
            this.$store.commit('showSuccess', resp.d.message);
            this.$bvModal.show('loginWindow');
          }).catch(e => {
            this.$router.push('/');
            this.$store.commit('showWarn', e.message);
          });
      }
    }
  },
  components: {
    Icon,
    BIconHouse,
  },
}
</script>
